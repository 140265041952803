     function getMobileOS() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

          // Windows Phone must come first because its UA also contains "Android"
          if (/windows phone/i.test(userAgent)) {
              return "windows";
          }

          if (/android/i.test(userAgent)) {
              return "android";
          }

          // iOS detection from: http://stackoverflow.com/a/9039885/177710
          if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
              return "ios";
          }

          return "unknown";
      }

      
      const cta = document.getElementById('header-cta');
      const header = document.getElementById('header');
      
      document.addEventListener('scroll', function() {
        var posY = window.scrollY;//document.documentElement.scrollTop;
        var t = document.getElementById('test-field');
        //t.innerHTML = 'Scrolling: ' + posY;

        if (posY > 300) {
          if( !header.classList.contains('is-scrolling')) {
            header.classList.add('is-scrolling');
            cta.classList.remove('btn-header');
            console.log(cta)
          }
        } else {
          if( header.classList.contains('is-scrolling')) {
            header.classList.remove('is-scrolling');
            cta.classList.add('btn-header');
          }
        }
        //console.log(posY)
      });
      
      class ResponsiveBackgroundImage {

        constructor(element) {
            this.element = element;
            this.img = element.querySelector('.responsive-hero-img');
            this.src = '';

            this.img.addEventListener('load', () => {
                this.update();
            });

            if (this.img.complete) {
                this.update();
            }
        }

        update() {
            let src = typeof this.img.currentSrc !== 'undefined' ? this.img.currentSrc : this.img.src;
            if (this.src !== src) {
                this.src = src;
                this.element.style.backgroundImage = 'url("' + this.src + '")';
                this.element.style.opacity = 1;
            }
        }
    }

    var el = document.querySelector('.responsive-hero');
    new ResponsiveBackgroundImage(el);

    const toggle = document.getElementById("mainNavToggle");
    const pageWrapper = document.getElementById('page-wrapper');
    const menu = document.getElementById('mobile-menu');
    const targetElement = document.querySelector("#mobile-menu");
    const logo = document.getElementById('header-logo');
    const hero = document.getElementById('hero');
    const topLink = document.getElementById('back-to-top-link');

    var _innerHeight = 0;
    var first = true;
    
    if(topLink) {
      topLink.addEventListener("click", function() {
        console.log('top')
        //window.scrollTo(0,0);
        window.scrollTo({top: 0, behavior: 'smooth'});
      });
    }
    
    toggle.addEventListener("click", function() {

        toggle.classList.toggle("is-active");
        toggle.setAttribute("aria-expanded", toggle.classList.contains('is-active'));
        //var menu = document.getElementById('mobile-menu');
        menu.classList.toggle('is-active');

        if (menu.classList.contains('is-active')) {
          menu.setAttribute('aria-hidden', false);
          bodyScrollLock.disableBodyScroll(targetElement);
          logo.classList.add('active');
          //console.log('Fuckers')
          header.classList.add('menu-open');
          

        } else {
          menu.setAttribute('aria-hidden', true);
          //document.body.style.position = 'static';
          logo.classList.remove('active');
          bodyScrollLock.enableBodyScroll(targetElement);
          header.classList.remove('menu-open');
        }
       // pageWrapper.classList.toggle('mobile-menu-open');
    }); 

    

    window.onresize = function() {
      
      var ih = window.innerHeight;
      if (ih !== _innerHeight && getMobileOS() == 'ios' && first) {
        hero.style.maxHeight = ih+'px';
        _innerHeight = ih;
        first = false;
      }
      
    }

    menu.classList.add('mobile-menu-animate');

    window.onresize();